import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, params) {
      return useJwt.getTasks(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getTaskAnalytics(params).then(response => response);
    },
    addTask(ctx, data) {
      return useJwt.createTask(data).then(response => response);
    },
    getTaskDetail(ctx, id) {
      return useJwt.getTaskDetail(id).then(response => response);
    },
    updateTask(ctx, data) {
      return useJwt.updateTask(data).then(response => response);
    },
    updateTaskStatus(ctx, data) {
      return useJwt.updateTaskStatus(data).then(response => response);
    },
    removeTask(ctx, { id }) {
      return useJwt.deleteTasks(id).then(response => response);
    },
    deleteTasks(ctx, data) {
      return useJwt.deleteTasks(data).then(response => response);
    },
    commentTask(ctx, data) {
      return useJwt.commentTask(data).then(response => response);
    },
    exportTasks(ctx, params) {
      return useJwt.exportTask(params).then(response => response);
    },
    extendDeadline(ctx, data) {
      return useJwt.extendDeadline(data).then(response => response);
    },
    forwardTask(ctx, data) {
      return useJwt.forwardTask(data).then(response => response);
    },
  },
};
