<template>
    <b-modal
      id="modal-forward-task"
      ref="refModal"
      :title="t('Chuyển tiếp công việc')"
      :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
      :ok-disabled="isSubmitting"
      :cancel-title="t('Huỷ')"
      cancel-variant="outline-secondary"
      :hide-header-close="true"
      no-close-on-backdrop
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="onSubmit"
    >
      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">{{ t('Bạn đang tiến hành chuyển tiếp công việc') }}: <strong>{{ itemLocal.title }}</strong>.</span>
          </div>
        </b-alert>
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
  
          <b-row>
            <b-col cols="12">
              <!-- due Date -->
              <validation-provider
              #default="validationContext"
              name="performer"
              rules="required"
            >
              <select-manager
                v-model="itemLocal.performer"
                :label="t('Người phụ trách')"
                required
                :default-value="itemLocal.performer && itemLocal.performer.user ? [{...itemLocal.performer.user, managerId: itemLocal.performer.id}] : []"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

            </b-col>
            <b-col />
          </b-row>
  
  
  
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import {
    BModal,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BAlert,
  } from 'bootstrap-vue';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { required } from '@validations';
  import formValidation from '@core/comp-functions/forms/form-validation';
  import useForwardTaskModal from './useForwardTaskModal';
  import SelectManager from '@/views/components/SelectManager.vue';
  
  export default {
    components: {
      BModal,
      BFormGroup,
      BFormTextarea,
      BForm,
      BFormInvalidFeedback,
      ValidationProvider,
      ValidationObserver,
      BRow,
      BCol,
      BAlert,

      SelectManager
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        required,
      };
    },
    setup(props, { emit }) {
      const {
        refFormObserver, getValidationState, resetForm, clearForm,
      } = formValidation();
  
      const {
        refModal,
        itemLocal,
        isSubmitting,
        resetItemLocal,
        resetModal,
        onSubmit,
        t,
      } = useForwardTaskModal(props, emit, refFormObserver);
  
      return {
        refModal,
        itemLocal,
        isSubmitting,
        resetItemLocal,
        resetModal,
        onSubmit,
        // Form Validation
        resetForm,
        clearForm,
        refFormObserver,
        getValidationState,
        t,
      };
    },
  };
  </script>
  