import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTaskTypes(ctx, params) {
      return useJwt.getTaskTypes(params).then(response => response);
    },
    createTaskType(ctx, data) {
      return useJwt.createTaskType(data).then(response => response);
    },
    updateTaskType(ctx, data) {
      return useJwt.updateTaskType(data).then(response => response);
    },
    deleteTaskTypes(ctx, data) {
      return useJwt.deleteTaskTypes(data).then(response => response);
    },
  },
};
