import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import taskStoreModule from '../taskStoreModule';

export default function useForwardTaskModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'task';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, taskStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const { t } = useI18nUtils();
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
        taskId: itemLocal.value.id,
        performerId: itemLocal.value.performer? itemLocal.value.performer.id : null
    }
    
    isSubmitting.value = true;

    store
      .dispatch('task/forwardTask', data)
      .then(response => { emit('on-item-updated', response.data); isSubmitting.value = false; })
      .then(() => {
        refModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
  };
}
