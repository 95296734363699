import {
  ref, watch, onUnmounted, onMounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import { useRouter } from '@/@core/utils/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import taskStoreModule from '../taskStoreModule';
import { getUserData } from '@/auth/utils';

export default function useTaskList() {
  const STORE_MODULE_NAME = 'task';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, taskStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  const { t } = useI18nUtils();
  // Use toast
  const toastification = toast();

  const currentUser = getUserData()

  // add / edit
  const blankItem = {
    id: 0,
    apartment: null,
    room: null,
    bed: null,
    title: '',
    priority: 'medium',
    priorityObject: { name: 'Bình thường', id: 'medium', value: 'medium' },
    description: '',
    asigneee: null,
    performers: [],
    type: null,
    dueDate: moment(new Date()).add(1, 'd').format("DD-MM-YYYY HH:mm"),
    attachments: [],
    deleteAttachmentIds: [],
    newDeadline: null,
    finishNote: '',
    finishDate: moment(new Date()).add(1, 'd').format("DD-MM-YYYY HH:mm"),
    finishAttachments: [],
  };
  const { route } = useRouter();
  const vm = getCurrentInstance().proxy;
  const routeParams = route.value.params;
  const item = ref(routeParams && routeParams.taskId && routeParams.taskId > 0 ? { id: routeParams.taskId } : JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = routeParams && routeParams.taskId && routeParams.taskId > 0 ? { id: routeParams.taskId } : JSON.parse(JSON.stringify(blankItem));
  };

  onMounted(() => {
    if (routeParams && routeParams.taskId && routeParams.taskId > 0) {
      vm.$bvModal.show('modal-task-detail');
    }
  });

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '100px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      sortable: false,
      width: '100px',
    },
    {
      label: t('Công việc'),
      field: 'title',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartment.name',
    },
    {
      label: t('Phòng'),
      field: 'room.name',
    },
    {
      label: t('Loại công việc'),
      field: 'type.name',
    },
    {
      label: t('Người thực hiện'),
      field: 'performers',
      sortable: false,
    },
    {
      label: t('Hạn hoàn thành'),
      field: 'dueDate',
    },
    {
      label: t('Trạng thái'),
      field: 'status',
      width: '200px',
    },

  ];
  const rows = ref([]);
  const analytics = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const apartment = ref(null);
  const room = ref(null);
  const status = ref(null);
  const type = ref(null);
  const performer = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  const fetchTasks = () => {
    store
      .dispatch('task/fetchTasks', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    store
      .dispatch('task/fetchAnalytics', serverParams.value)
      .then(response => {
        analytics.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchTasks();
    fetchAnalytics();
  };

  const deleteTasks = tasks => {
    store
      .dispatch('task/deleteTasks', {
        ids: tasks.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const updateTaskStatus = data => {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('status', data.status);
    if (data.finishNote) {
      formData.append('finishNote', data.finishNote);
    }

    store
      .dispatch('task/updateTaskStatus', formData)
      .then(() => {
        fetchData();
        toastification.showToastSuccess('Đã nhận xử lý công việc thành công. Hãy đảm bảo tiến hành công việc đúng hạn và xác nhận sau khi hoàn thành.');
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('task/exportTasks', serverParams.value)
      .then(response => {
        if (response.data === true) {
          vm.$router.push({ name: 'jobs' });
        } else {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = { ...data, finishDate: moment(new Date()).add(1, 'd').format("DD-MM-YYYY HH:mm"), finishAttachments: [] };
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });
  watch(performer, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.performerId = val.id;
    } else {
      delete filter.performerId;
    }
    updateParams({ filter });
  });
  watch(startDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.startDate = val;
    } else {
      delete filter.startDate;
    }
    updateParams({ filter });
  });
  watch(endDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.endDate = val;
    } else {
      delete filter.endDate;
    }
    updateParams({ filter });
  });
  watch(route, val => {
    if (val.params && val.params.taskId && val.params.taskId > 0) {
      onEditItem({ id: val.params.taskId });
      vm.$bvModal.show('modal-task-detail');
    }
  });

  //   API Call

  const refetchTasks = () => {
    fetchTasks();
  };

  // UI
  const resolvePerformerNames = val => {
    if (!val || !val.performers) {
      return '';
    }
    return val.performers.map(_obj => _obj.name).join(', ');
  };

  const currentUserIsPerformer = val => {
    if (currentUser) {
      if (val && val.performers && val.performers.filter(obj => obj.id === currentUser.id).length > 0) {
        return true
      }
    }
    return false
  }

  return {
    item,
    exportData,
    resetItem,
    analytics,
    columns,
    rows,
    selectedRows,
    searchTerm,
    apartment,
    room,
    status,
    type,
    isLoading,
    totalRecords,
    serverParams,
    performer,
    startDate,
    endDate,
    fetchData,
    refetchTasks,
    fetchTasks,
    deleteTasks,
    updateTaskStatus,
    resolvePerformerNames,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    currentUserIsPerformer,
    t,
  };
}
